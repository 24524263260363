
export const URL_AUTH = {
	API_TOKEN_AUTH: "/api/auth/api-token-auth/",
	SIGN_OUT: "/api/auth/sign-out/",
	USERS: "/api/auth/users/",
	USERS_ME: "/api/auth/users/me/",
	USERS_CHANGE_PASSWORD: "/api/auth/users/change_password/",
	REQUEST_RESET_PASSWORD: "/api/auth/request-reset-password/",
}

export const URL_CORE = {
	PROVINCE: "/api/core/province/",
	DISTRICT: "/api/core/district/",
	SUB_DISTRICT: "/api/core/sub-district/",
	THAI_ADDRESS: "/api/core/thai-address/"
}

export const URL_WEIGHT = {
	FORM: "/api/weight/form/",
	TRANSACTION: "/api/weight/transaction/",
	MEASURE: "/api/weight/measure/",
	FORM_PAYMENT: "/api/weight/form-payment/",
	PRINT: "/api/weight/print/",
	CUSTOMER_TICKET_DETAIL: "/api/weight/customer-ticket-detail/",
	CUSTOMER_LIST_TICKET: "/api/weight/customer-list-ticket/",
	WEIGHT_WOOD_PLOT_TRANSACTION: "/api/weight/weight-wood-plot-transaction/",
	CURRENT_WOOD_PLOT_STATUS: "/api/weight/current-wood-plot-status/"
}

export const URL_CUSTOMER = {
	CUSTOMER: "/api/customer/customer/",
	VEHICLE: "/api/customer/vehicle/",
	PRODUCT: "/api/customer/product/",
	CREATE_LIFF: "/api/customer/create-liff/",
	VIEW_CUSTOMER_OTP: "/api/customer/view-customer-otp/",
	GET_CUSTOMER_BY_LIFF: "/api/customer/get-customer-by-liff/"
}

export const URL_WALLET = {
	DETAIL: "/api/wallet/detail/",
	TRANSACTION: "/api/wallet/transactions/",
	DEPOSIT: "/api/wallet/deposit/",
	WITHDRAW: "/api/wallet/withdraw/", // Don't used
}

export const URL_LOG = {
	WEIGHT_FORM: "/api/log/weight-form/",
	WEIGHT_TRANSACTION: "/api/log/weight-transaction/",
	DELETED_TRANSACION: "/api/log/deleted-transaction/"
}

export const URL_COMPANY = {
	COMPANY: "/api/company/company/",
	COMPANY_SHIFT: "api/company/company-shift/",
	CHECK_SHIFT_STATUS: "api/company/check-shift-status/",
	OPEN_DRAWER: "/api/cashier/open-drawer/",
}

export const URL_DASHBOARD = {
	SALES: "/api/dashboard/sales/",
	SALES_DETAIL: "/api/dashboard/sales-detail",
	DEBT: "/api/dashboard/debt/",
	DEBT_DETAIL: "/api/dashboard/debt-detail/",
	NET_WEIGHT: "/api/dashboard/net-weight/",
	NET_WEIGHT_DETAIL: "/api/dashboard/net-weight-detail/",
	PASSED_VEHICLE: "/api/dashboard/passed-vehicle/",
	PASSED_VEHICLE_DETAIL: "/api/dashboard/passed-vehicle-detail/",
	DELETED_TRANSACION: "/api/dashboard/deleted-transaction/",
	DELETED_TRANSACION_DETAIL: "/api/dashboard/deleted-transaction-detail/",
	CUSTOMER_INCOME: "/api/dashboard/customer-income/",
	CUSTOMER_INCOME_DETAIL: "/api/dashboard/customer-income-detail/",
	CUSTOMER_EXPENSE: "/api/dashboard/customer-expense/",
	CUSTOMER_EXPENSE_DETAIL: "/api/dashboard/customer-expense-detail/",
	BEST_SELLING_PRODUCT: "/api/dashboard/best-selling-product/",
	INCOME_PER_CUSTOMER: "/api/dashboard/income-per-customer/",
}

export const URL_REPORT = {
	TICKET: "/api/report/ticket-pdf/",
	PRODUCT_SUMMARY: "/api/report/product-summary-pdf/",
	COMPANY_SHIFT: "/api/report/company-shift/",
	CUSTOMER_REPORT: "/api/report/weighing_separately_by_customer/",
	DATE_REPORT: "/api/report/weighing_separately_by_date/",
	PRODUCT_REPORT: "/api/report/weighing_separately_by_product/",
	PRODUCT_ALL_REPORT: "/api/report/product-summary-all-pdf/",
}