import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Icon, Image, Modal } from "semantic-ui-react";
import propTypes from "prop-types";
import PromptpayIcon from '../../assets/promptpay_icon.png';
import QRCode from 'qrcode.react';
import { formatComma } from "../../utils/Util";

const generatePayload = require('promptpay-qr');

export default function PromptPayModal(props) {
  const { t } = useTranslation();
	const [promptPayQR, setPromptPayQR] = React.useState(null);

  React.useEffect(() => {
    if (props.open) {
			if (props.promptPayAccount) {
				// Generate new QRCode image
				const amount = props.amount
				setPromptPayQR(generatePayload(props.promptPayAccount, { amount }));
			}
    }
  }, [props.open])

  return (
    <Modal
			open={props.open}
			onClose={props.onClose}
			size={"tiny"}
			centered={true}
      
		>
			<Grid centered style={{margin: 24}}>
				<Grid.Row centered>
					<Image floated='center' src={PromptpayIcon} size='medium' />
				</Grid.Row>
				<Grid.Row centered>
					{promptPayQR && (
						<QRCode value={promptPayQR} includeMargin={true} size={256} />
					)}
				</Grid.Row>
				<Grid.Row style={{fontSize: 22, paddingTop: 0}}>จำนวน: {formatComma(props.amount)} บาท</Grid.Row>
			</Grid>
			<Modal.Actions>
					<Button 
						primary 
						onClick={() => props.onPaid(props.amount)}>
						<Icon name={"money"}/>
						{t("paid")}
					</Button>
					<Button 
						color={"grey"} 
						onClick={props.onClose}>
						<Icon name={"cancel"}/>
						{t("close")}
					</Button>
			</Modal.Actions>
		</Modal>
  )
}

PromptPayModal.defaultProps = {
  open: false,
	promptPayAccount: null,
	amount: 0,
  onClose: () => null,
	onPaid: (amount) => null,
}

PromptPayModal.propTypes = {
  open: propTypes.bool,
	promptPayAccount: propTypes.any,
	amount: propTypes.number,
  onClose: propTypes.func,
	onPaid: propTypes.func,
}
