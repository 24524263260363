import React, { useState, useEffect, useMemo } from 'react';
import { DELETE, GET } from '../../utils/HttpClient';
import { URL_CUSTOMER } from "../../constances/urls";
import ListTable from '../common/ListTable';
import { Button, Icon } from 'semantic-ui-react';
import ProductDetailModal from './ProductDetailModal';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../common/ConfirmModal';
import { Toast } from "../../utils/Toast";

export default function ProductSetting () {
  
  const { t, i18n } = useTranslation();
  const [data, setData] = useState({
    count: 0,
    pages: 0,
    links: {
      next: null,
      previous: null,
    },
    results: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const tableRef = React.useRef();

  const columns = useMemo(() => [
    {
      Header: t('products.name'),
      accessor: 'name',
    },
    {
      Header: t('products.price_per_unit'),
      accessor: 'price_per_unit',
      Cell: ({row}) => `${row.original.price_per_unit} ${t('units.baht')}`
    },
    {
      Header: t('products.unit'),
      accessor: 'unit',
      Cell: ({row}) => `${t(`units.${row.original.unit}`)}`
    },
    { Header: '',
      accessor: 'id',
      Cell: cell => {
        const isActive = cell.row.original.is_active;
        const isFsc = cell.row.original.is_fsc;
        return (
        <div style={{textAlign: 'center'}} >
          {isActive && !isFsc && (
            <div>
              <Button color='blue' basic onClick={() => editProduct(cell.row.original, false)}>
                <Icon name='edit'/>
                {t('edit')}
              </Button>
              <Button color='red' basic onClick={() => {deleteProduct(cell.row.original)}}>
                <Icon name='delete'/>
                {t('delete')}
              </Button>
            </div>
          )}
          {isActive && isFsc && (
            <div>
              <Button color='green' basic onClick={() => editProduct(cell.row.original, true)}>
                <Icon name='eye' />
                {t('view')}
              </Button>
            </div>
          )}
          {!isActive && !isFsc && (
            <Button color='blue' basic onClick={() => editProduct(cell.row.original, false)}>
              <Icon name='sync alternate'/>
              {t('reactivate')}
            </Button>
          )}
        </div>
      )}
    },
  ], []);

  const fetchData = async (params) => {
    if (!params) {
      params = tableRef.current.getParams();
    }
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await GET(URL_CUSTOMER.PRODUCT, params);
      setData(response.data);
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleDeleteProduct = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    setIsDeleteVisible(false);
    try {
      const response = await DELETE(`${URL_CUSTOMER.PRODUCT}${selectedProduct.id}/`)
      Toast.success(`${t('delete')} ${t('messages.success')}`);
      fetchData();
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const createProduct = () => {
    setIsModalVisible(true);
    setSelectedProduct(null);
  }

  const editProduct = (product, readOnly) => {
    setIsReadOnly(readOnly)
    setIsModalVisible(true);
    setSelectedProduct(product);
  }

  const deleteProduct = (product) => {
    setIsDeleteVisible(true);
    setSelectedProduct(product);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <ConfirmModal
        open={isDeleteVisible}
        title={t("products.delete_product")}
        detail={t("products.msg_confirm_delete_product", 
          {name: (selectedProduct ? selectedProduct.name : "")})}
        onClose={() => {
          setIsDeleteVisible(false);
          setSelectedProduct(null);
        }}
        onConfirm={handleDeleteProduct}
        onCancel={() => {
          setIsDeleteVisible(false);
          setSelectedProduct(null);
        }}      
      />

      <ProductDetailModal
        open={isModalVisible}
        readOnly={isReadOnly}
        onClose={() => setIsModalVisible(false)}
        product={selectedProduct}
        refetch={fetchData}
      />
      <ListTable
        ref={tableRef}
        columns={columns}
        data={data}
        loading={isLoading}
        error={errorMessage}
        onCreate={createProduct}
        refetch={fetchData}
        showActionHeader
        showPagination
      />
    </div>
  );
}