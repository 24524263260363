import React from 'react';
import propTypes from 'prop-types';
import loading from '../../assets/lottie_report.json';
import { Button, Modal, Image } from 'semantic-ui-react';
import Lottie from "lottie-react";
import { GET } from '../../utils/HttpClient';
import { Toast } from '../../utils/Toast';


const ReportExportButton = React.forwardRef((props, ref) => {
  const [showModal, setShowModal] = React.useState(false)
  const [blobData, setBlobData] = React.useState(null)

  React.useEffect(() => {
    if (showModal && blobData != null) {
      window.open(URL.createObjectURL(blobData));
      setShowModal(false);
      setBlobData(null);
    }
  }, [blobData])

  React.useImperativeHandle(ref, () => ({
    generateReport: generateReport,
  }));

  const generateReport = async () => {
    // const params = props.params()

    // console.log("Params")
    // console.log(params)
    // if (params == null) {
    //   setShowModal(false);
    //   return; // Ignore false validation
    // }

    if (showModal) {
      return;  // Do nothing if modal already displayed
    }
    else if (props.url == null) {
      Toast.error("No url parameter")
      return;
    }

    setShowModal(true)
    try {
      const response = await GET(props.url, props.params, false, true) // Download as blob
      setBlobData(response.data)
    } catch (error) {
      setShowModal(false);
      Toast.error(`Error exporting : ${error.errorMessages}`)
    }
  }

  return (
    <Modal
      onClose={() => setShowModal(false)}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={showModal}
      size="mini"
      trigger={
        <Button
          color={props.type === "excel" ? 'green' : 'red'}
          icon={props.type === "excel" ? 'file excel' : 'file pdf'}
          content={props.type === "excel" ? 'Excel' : 'PDF'}
          onClick={props.onClick == null ? generateReport : props.onClick}
          size={props.size}
          disabled={props.disabled}
        />
      }
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content>
        <Lottie animationData={loading} loop={true}/>;
      </Modal.Content>
    </Modal>
  )
})

ReportExportButton.defaultProps = {
  url: "",
  type: "", // excel or pdf
  params: {}, 
  onClick: null,
  title: "",
  size: "mini",
  disabled: false,
}

ReportExportButton.propTypes = {
  url: propTypes.string,
  type: propTypes.string, // excel or pdf
  params: propTypes.object,
  onClick: propTypes.func,  // for override default onClick and useRef to call generateReport manually
  title: propTypes.string,
  size: propTypes.string,
  disabled: propTypes.bool,
}

export default ReportExportButton;