import React from 'react'
import { Dimmer, Loader, Message, Header, Segment, Button } from 'semantic-ui-react'
import { useHistory, useParams } from "react-router-dom";
import Cookies from 'js-cookie'
import { useLiff } from 'react-liff-v2';
import LoginView from '../components/common/LoginView';
import LiffLogin from "../components/common/LiffLogin";
import { PATH } from '../routes/Routes';
import { URL_CUSTOMER } from '../constances/urls';
import { GET } from '../utils/HttpClient';

export default function LiffLoginScreen() {
  const history = useHistory();
  const { app } = useParams();
  const { error, liff, isLoggedIn, ready } = useLiff();
  const [nextPath, setNextPath] = React.useState(null)

  React.useEffect(() => {
    setNextPath(`/liff-app/${app}`);  // Manual set next route from given URL
  }, [app])

  const handleOnLoggedIn = () => {
    history.push(nextPath);
  };

  React.useEffect(() => {
    if (ready && nextPath != null) {
      const customer = Cookies.get('customer')
      if (customer) {
        // Already logged in, auto redirect
        history.push(nextPath);
      }
    }
  }, [nextPath])

  React.useEffect(() => {
    if (isLoggedIn && Cookies.get('customer') != null) {
      // Already logged in
      history.push(nextPath);
    }
  }, [isLoggedIn]);

  return (
    <>
      {!ready &&
        <Dimmer active>
          <Loader>เชื่อมต่อกับ LINE...</Loader>
        </Dimmer>
      }

      {ready && !isLoggedIn &&
        <div>
          <LiffLogin onClick={() => { liff.login() }} />
        </div>
      }

      {ready && isLoggedIn && Cookies.get('customer') == null &&
        <LoginView onLoggedIn={handleOnLoggedIn} liff={liff} />
      }

      {error &&
        <Message
          negative
          icon="exclamation circle"
          header="Error on Line LIFF"
          content={error}
        />
      }
    </>
  )
}