import React, { useEffect } from 'react';
import propTypes from "prop-types";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Header, Icon, Card, Popup, Dimmer, Loader } from 'semantic-ui-react';
import ScaleCard from "./ScaleCard";
import VehicleCard from "../vehicle/VehicleCard";
import ProductCard from "../product/ProductCard";
import { DELETE, GET, PATCH, POST } from "../../utils/HttpClient";
import { URL_WEIGHT, URL_CUSTOMER, URL_REPORT } from "../../constances/urls";
import LogModal from '../common/LogModal';
import Cookies from "js-cookie";
import { SERVER } from "../../Config";
import ConfirmWithNoteModal from '../common/ConfirmWithNoteModal';
import TransactionNoteModal from './TransactionNoteModal';
import HistoryTransactionModal from './HistoryTransactionModal';
import { Toast } from '../../utils/Toast';


const TransactionCard = React.forwardRef((props, ref) => {
	const { t, i18n } = useTranslation();
	const [loading, setLoading] = React.useState(false);
	const [loadingMessage, setLoadingMessage] = React.useState(t("loading"))
	const [openConfirm, setOpenConfirm] = React.useState(false);
	const [trigger, setTrigger] = React.useState(false);
	const [transactionCode, setTransactionCode] = React.useState(null);
	const inScaleRef = React.useRef(null);
	const outScaleRef = React.useRef(null);
	const vehicleRef = React.useRef(null);
	const productRef = React.useRef(null);
	// Log
	const [isLogVisible, setIsLogVisible] = React.useState(false);
	// Note
	const [note, setNote] = React.useState('');
	const [isAddNoteVisible, setIsAddNoteVisible] = React.useState(false);
	// History transaction
	const [isTransactionHistoryVisible, setIsTransactionHistoryVisible] = React.useState(false);

	React.useImperativeHandle (ref, () => ({
		vehicleRef,
		productRef,
		validation: async() => {
			// vehicle
			const vehicleForm = vehicleRef.current.formik;
			const vehicleValidation = await vehicleForm.validateForm();
			// product
			const productForm = productRef.current.formik;
			const productValidation = await productForm.validateForm();
			return ((Object.keys(vehicleValidation).length === 0) 
				&& (Object.keys(productValidation).length === 0));
		},
		create: async (formId) => {
			return await handleTransaction(formId, true);
		},
		update: async (formId) => {
			return await handleTransaction(formId, false);
		},
		delete: async (memo) => {
			return await deleteTransaction(props.transactionId, memo);
		},
		print: async () => {
			return await printTransaction(props.transactionId);
		}
	}));

	const handleTransaction = async (formId, isCreate) => {
		const vehicleForm = vehicleRef.current.formik;
		const productForm = productRef.current.formik;
		let vehicleId = vehicleForm.values.id;
		// Create or update vehicle
		const [isVehicleSuccess, vehicleResponse] = await createVehicle({...vehicleForm.values});
		if (isVehicleSuccess) {
			vehicleId = vehicleResponse.data.id;
		} else {
			return [false, null];
		}
		// update product
		const [isProductSuccess, productResponse] = await updateProduct({...productForm.values});
		if (!isProductSuccess) {
			return [false, null];
		}
		// Prepare data
		let data = {
			form: formId,
			vehicle: vehicleId,
			product: productForm.values.id,
			price_per_unit: productForm.values.price_per_unit,
			unit: productForm.values.unit,
			price_gross_weight: productForm.values.price_gross_weight,
			drc: productForm.values.drc,
			note: note,
		}
		let inDateTime = inScaleRef.current.dateTime;
		if (inDateTime) {
			data.in_date = getDateTimeToString(inDateTime);
			let weight = String(inScaleRef.current.weight);
			data.in_weight = parseInt(weight.replaceAll(",", ""));
			data.in_unit = "kilo_gram";
		}
		let outDateTime = outScaleRef.current.dateTime;
		if (outDateTime) {
			data.out_date = getDateTimeToString(outDateTime);
			let weight = String(outScaleRef.current.weight);
			data.out_weight = parseInt(weight.replaceAll(",", ""));
			data.out_unit = "kilo_gram";
		}
		
		if (isCreate) {
			// Create
			const [isSuccess, response] = await createTransaction(data);
			return [isSuccess, response];
		} else {
			// Update
			const [isSuccess, response] = await updateTransaction(props.transactionId, data);
			return [isSuccess, response];
		}
	}

	const createTransaction = async (data) => {
		let response = null;
		let isSuccess = true;
		try {
			response = await POST(URL_WEIGHT.TRANSACTION, data);
		} catch(error) {
			console.log("Error: ", error);
			isSuccess = false;
		}
		return [isSuccess, response];
	}

	const updateTransaction = async (uid, data) => {
		let response = null;
		let isSuccess = true;
		let error = null;
		try {
			response = await PATCH(`${URL_WEIGHT.TRANSACTION}${uid}/`, data);
		} catch(e) {
			console.log("Error: ", e);
			error = e
			isSuccess = false;
		}
		return [isSuccess, response, error];
	}

	const deleteTransaction = async (uid, memo) => {
		let response = null;
		let isSuccess = true;
		try {
			response = await DELETE(`${URL_WEIGHT.TRANSACTION}${uid}/`, {params: memo});
		} catch(error) {
			console.log("Error: ", error);
			isSuccess = false;
		}
		return [isSuccess, response];
	}

	const printTransaction = async (uid) => {
		let response = null;
		let isSuccess = true;
		setLoadingMessage(t("prints.connecting"));
		setLoading(true);
		try {
			// Get report
			response = await GET(`${URL_REPORT.TICKET}${uid}/`, {}, false, true);
			// Print
			setLoadingMessage(t("prints.printing"));
			const serverIP = Cookies.get("server");
			const serverPort = Cookies.get("server_port");
			const data = {
				'files': response.data
			}
			await POST(
				`http://${serverIP ? serverIP : SERVER.DEFAULT_IP}:${serverPort ? serverPort : SERVER.DEFAULT_PORT}${URL_WEIGHT.PRINT}`
				, data, true
			);
		} catch (error) {
			console.log("Error: ", error);
			isSuccess = false;
		} finally {
			setLoading(false);
			setLoadingMessage(t("loading"));
		}
		
		return [isSuccess, response];
	}

	const createVehicle = async (data) => {
		let response = null;
		let isSuccess = true;
		try {
			response = await POST(URL_CUSTOMER.VEHICLE, data);
		} catch (error) {
			console.log("Error: ", error);
			isSuccess = false;
		}
		return [isSuccess, response];
	}

	const updateProduct = async (data) => {
		let response = null;
		let isSuccess = true;
		try {
			response = await PATCH(`${URL_CUSTOMER.PRODUCT}${data.id}/`, data)
		} catch (error) {
			isSuccess = false;
		}
		return [isSuccess, response];
	}

	// Convert Date to string
	const getDateTimeToString = (date) => {
		if (!date)
			return "";
		
		const sDate = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
		const sTime = `${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}:${("0" + date.getSeconds()).slice(-2)}`
		return `${sDate} ${sTime}`;
	}

	const handleAutoSaveWeight = async (date, weight) => {
		if (props.transactionId.startsWith("temp")) {
			return ;
		}
		setLoading(true);

		const data = {
			in_weight: weight,
			in_date: date,
		}

		const [isSuccess, response] = await updateTransaction(props.transactionId, data);
		if (!isSuccess) {
			Toast.warning(t("scales.error_cannot_auto_save_weight"))
		}
		setLoading(false);
	}

	const handleSaveNoteInTransaction = async (value) => {
		let response = null;
		let isSuccess = true;
		let error = null;

		if (!props.transactionId.startsWith("temp")) {
			[isSuccess, response, error] = await updateTransaction(props.transactionId, {note: value})
		}
		setNote(value)

		return [isSuccess, response, error];
	}

  useEffect(() => { 
		if (props.initialData) {
			let data = props.initialData;
			vehicleRef.current.initial(data.vehicle);
			if (data.product) {
				productRef.current.initial({
					...data.product,
					price_per_unit: parseFloat(data.price_per_unit).toFixed(2),
					unit: data.unit,
					price_gross_weight: parseFloat(data.price_gross_weight).toFixed(2),
					drc: data.drc == null ? 0 : parseFloat(data.drc).toFixed(2),
				});
			}
			// Set scale in
			let inDateTime = null;
			if (data.in_date) {
				inDateTime = new Date(moment(data.in_date, "YYYY-MM-DD HH:mm:ss"));
			}
			inScaleRef.current.initial(inDateTime, data.in_weight);
			// Set scale out
			let outDateTime = null;
			if (data.out_date) {
				outDateTime = new Date(moment(data.out_date, "YYYY-MM-DD HH:mm:ss"));
			}
			outScaleRef.current.initial(outDateTime, data.out_weight);

			// initial transaction name
			if (data.code) {
				setTransactionCode(data.code);
			}
			// initial note
			if (data.note) {
				setNote(data.note);
			}
		}
	}, [props.initialData]);
	
  return (
		<div style={{margin: "24px", ...props.style}}>
			<Dimmer active={loading}>
        <Loader>{loadingMessage}</Loader>
      </Dimmer>

			<LogModal
				open={isLogVisible}
				onClose={() => setIsLogVisible(false)}
				objectId={props.transactionId}
				objectType='WEIGHT_TRANSACTION'
			/>

			<TransactionNoteModal
				open={isAddNoteVisible}
				onClose={() => setIsAddNoteVisible(false)}
				note={note}
				setNote={(value) => setNote(value)}
				saveNoteInTransaction={handleSaveNoteInTransaction}
			/>

			<HistoryTransactionModal
				open={isTransactionHistoryVisible}
				onClose={() => setIsTransactionHistoryVisible(false)}
				vehicleRef={vehicleRef}
				setWeightFromSelectTransaction={(weight) => {
					inScaleRef.current.initial(new Date(), weight)
					setIsTransactionHistoryVisible(false)
				}}
			/>

			<Card fluid>
				<Card.Content 
					style={{
						textAlign: "left", 
						display: "flex", 
						paddingLeft: 16, 
						paddingRight: 8,
						paddingTop: 8,
						paddingBottom: 8}}>
					<div 
						style={{
							width: "80%", 
							display: "flex", 
							alignItems: "center"
						}}>
						<Header>{`${t("scales.ticket")}: ${(transactionCode ? transactionCode : "")}`}</Header>
					</div>
					<div style={{width: "20%", display: "flex", justifyContent: 'flex-end'}}>
						<Popup 
							content={note ? `${t('memo')}: ${note}` : t('scales.add_note')}
							inverted={note ? true : false}
							position='bottom right'
							trigger={
								<Icon
									onClick={() => setIsAddNoteVisible(true)}
									name='bullhorn'
									size='large'
									color={note ? 'green' : 'grey'} />
							}
						/>
						{ props.shiftReady &&
							<Popup 
								content={t("log")}
								trigger={
									<Icon
										onClick={() => setIsLogVisible(true)}
										name='file alternate outline'
										size='large'
										color='blue' />
								}
							/>
						}
						{!props.disablePrint && (
							<Popup 
								content={t("print")}
								trigger={
									<Icon
										onClick={async () => {
											const [isSuccess, response] = await printTransaction(props.transactionId);
											props.onPrint(isSuccess, response);
										}}
										name='print'
										size='large'
										color='blue' />
								}
							/>
						)}
						{(props.shiftReady && !props.isPaid) &&
							<Popup 
								content={t("delete")}
								trigger={
									<Icon
										name={"delete"}
										color={"red"} 
										size={"large"}
										onClick={() => setOpenConfirm(true)} />
								}
							/>
						}
					</div>
				</Card.Content>
				
				<Card.Content textAlign={"left"}>
					<div
						style={{marginLeft: 24, marginRight: 24, marginBottom: 16}}>
						<ProductCard 
							ref={productRef} 
							onProductChange={(product) => props.onProductChange(product)}/>
						<VehicleCard 
							ref={vehicleRef}
							onChange={(data) => props.onVehicleChange(data)}/>
					</div>

					<ScaleCard 
						style={{marginLeft: 24, marginRight: 24}}
						ref={inScaleRef}
						title={t("scales.weigh_in")}
						initialDateTime={null}
						initialWeight={null}
						onChange={(date, weight) => setTrigger(!trigger)}
						enableHistoryTransacion={(props.index > 0 && props.initialData.vehicle && props.enableTransactionHistory)}
						openTransactionHistory={() => setIsTransactionHistoryVisible(true)}
						onAutoSaveTransaction={handleAutoSaveWeight}/>
					<ScaleCard 
						style={{marginTop: 24, marginLeft: 24, marginRight: 24}}
						ref={outScaleRef}
						title={t("scales.weigh_out")}
						disabled={(inScaleRef.current && inScaleRef.current.weight) ? false : true}
						initialDateTime={null}
						initialWeight={null}/>
				</Card.Content>
			</Card>

			<ConfirmWithNoteModal
				title={t("scales.delete_ticket")}
				detail={props.initialData ? t("scales.msg_confirm_delete_ticket", {name: props.initialData.code || ''}) : ""}
				open={openConfirm}
				onClose={() => setOpenConfirm(false)}
				onConfirm={(memo) => {
					setOpenConfirm(false);
					props.onDelete(props.transactionId, memo);
				}}
			/>
		</div>
	)
});

TransactionCard.defaultProps = {
	style: null,
	key: 0,
	index: 0,
	transactionId: "",
	initialData: null,
	disablePrint: true,
	onPrint: (isSuccess, data) => {},
	onDelete: () => {},
	onVehicleChange: (data) => {},
	onProductChange: (data) => {},
	enableTransactionHistory: false,
	shiftReady: false,
	isPaid: false,
}

TransactionCard.propTypes = {
	style: propTypes.object,
	key: propTypes.number,
	index: propTypes.number,
	transactionId: propTypes.string,
	initialData: propTypes.object,
	disablePrint: propTypes.bool,
	onPrint: propTypes.func,
	onDelete: propTypes.func,
	onVehicleChange: propTypes.func,
	onProductChange: propTypes.func,
	enableTransactionHistory: propTypes.bool,
	shiftReady: propTypes.bool,
	isPaid: propTypes.bool,
}

export default React.memo(TransactionCard);