import React from 'react';
import propTypes from "prop-types";
import { Button, Divider, Form, Icon, Input, Label, Message, Progress, Segment } from 'semantic-ui-react';
import { formatComma } from "../../utils/Util";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { GET, POST, PUT } from '../../utils/HttpClient';
import { URL_WEIGHT } from '../../constances/urls';

const PlotItem = React.forwardRef(({item, formId, enableKnife}, ref) => {
	const { t, i18n } = useTranslation();
	const [defPlot, setDefPlot] = React.useState(null);
	const [percent, setPercent] = React.useState(0);
	const [dryLatex, setDryLatex] = React.useState(0);

	const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: {net_weight: "0", knife: "1"},
		validationSchema: Yup.object().shape({
			net_weight: Yup.number().required(t("required")),
			knife: enableKnife ? Yup.number().required(t("required")) : Yup.number(),
		}),
	});

	React.useImperativeHandle(ref, () => ({
		formik,
		getData: async () => {
			return {
				wood_plot: item.id,
				net_weight_unit: "kilo_gram",
				...formik.values
			}
		}
	}));

	const handleFocus = (event) => event.target.select();

	const fetchDefault = async () => {
		const plotId = (item.form && item.wood_plot_detail) ? item.wood_plot_detail.id : item.id
		try {
			const response = await GET(`${URL_WEIGHT.CURRENT_WOOD_PLOT_STATUS}${formId}/${plotId}/`);
			setDefPlot(response.data)
		} catch (error) {
			console.log("Error: ", error);
		}
	}

	React.useEffect(() => {
		if (formik.values.knife && defPlot) {
			const area = parseInt((item.form && item.wood_plot_detail) ? item.wood_plot_detail.area : item.area)
			const dry = (3 * area * formik.values.knife).toFixed(2)
			const percentage = (defPlot.today_total_weight * 100 / dry).toFixed(2)
			setDryLatex(dry)
			setPercent(percentage)
		} else {
			setDryLatex(0)
			setPercent(0)
		}
	}, [formik.values.knife, defPlot])

	React.useEffect(() => {
		fetchDefault()
		formik.resetForm()
		// wood plot saved
		if (item && item.form) {
			formik.setValues(item)
		}
		else if (item && item.default_knife) {
			formik.setFieldValue('knife', item.default_knife)
		}

	}, [item])

	return(
		<Segment key={item.id}>
			<Form>
				<Form.Group widths={"equal"}>
					<Form.Field style={{textAlign: "right"}}>
						<Button as='div' labelPosition='right'>
							<Button color='blue'>
								<Icon name='tag' />{(item.form && item.wood_plot_detail) ? item.wood_plot_detail.code : item.code}
							</Button>
							<Label as='a' basic color='blue' pointing='left'>
								{` ขนาด ${formatComma((item.form && item.wood_plot_detail) ? item.wood_plot_detail.area : item.area)} ไร่`}
							</Label>
						</Button>
					</Form.Field>

					<Form.Field
						required
						fluid
						error={formik.errors.net_weight}>
						<Input
							fluid
							label={{ content: t("units.kilo_gram") }}
							labelPosition='right'
							name="net_weight"
							onFocus={handleFocus}
							value={formik.values.net_weight}
							onChange={formik.handleChange}
						/>	
					</Form.Field>
					
					{enableKnife && (
						<Form.Field
						required
						fluid
						error={formik.errors.knife}>
							<Input
								fluid
								placeholder={t("tapping")}
								label={{ content: t("days") }}
								labelPosition='right'	
								name="knife"
								onFocus={handleFocus}
								value={formik.values.knife}
								onChange={formik.handleChange}
							/>	
						</Form.Field>
					)}
				</Form.Group>
			</Form>
			{enableKnife && (dryLatex > 0) && (
				<Progress 
					progress
					percent={percent} 
					color={(percent <= 70) ? "blue" : (percent > 90) ? "red" : "yellow"}
					content={`น้ำหนักที่ขายแล้ว ${formatComma(defPlot.today_total_weight)} / น้ำหนักน้ำยางแห้ง ${formatComma(dryLatex)} (คงเหลือ ${formatComma(dryLatex - item.today_total_weight)} กิโลกรัม)`}/>
			)}
		</Segment>
	)
})

const WoodPlotCard = React.forwardRef((props, ref) => {
	const { t, i18n } = useTranslation();
	const [errorMessage, setErrorMessages] = React.useState();
	const [enableKnife, setEnableKnife] = React.useState(false);
	const [plots, setPlots] = React.useState([]);
	const plotRef = React.useRef([]);

	React.useImperativeHandle(ref, () => ({
		validation: async () => await handleValidate(),
		save: async () => await handleSave(false)
	}));

	const handleValidate = async () => {
		let isValid = true;
			for (let index in plots) {
				const plotForm = plotRef.current[index].formik;
				const plotValidation = await plotForm.validateForm();
				let isPlotValid = (Object.keys(plotValidation).length === 0)
				if (!isPlotValid) {
					isValid = false;
				}
			}
			return isValid
	}

	const handleSave = async (callBack = true) => {
		setErrorMessages(null);
		const isValid = await handleValidate();
		if (!isValid) return false;

		// Prepare data
		let list = []
		for (let index in plots) {
			const data = await plotRef.current[index].getData();
			if (data) {
				if (data.form) {
					// Update wood plot
					const [isSuccess, response] = await updateWoodPlot(data);
					if (!isSuccess) {
						setErrorMessages(response.errorMessages);
						return;
					} 
				} else {
					// Add to list for create
					list.push({form: props.formId, ...data});
				}
			}
		}

		if (list.length > 0) {
			const [isSuccess, response] = await createWoodPlot(list);
			if (!isSuccess) {
				setErrorMessages(response.errorMessages);
				return;
			}
		}
		
		if (callBack)
			props.onSaved()

		return true;
	}

	const createWoodPlot = async (list) => {
		let response = null;
		let isSuccess = true;
		try {
			response = await POST(URL_WEIGHT.WEIGHT_WOOD_PLOT_TRANSACTION, list);
		} catch (error) {
			response = { errorMessages: error.errorMessages }
			isSuccess = false;
		}
		return [isSuccess, response];
	}

	const updateWoodPlot = async (data) => {
		let response = null;
		let isSuccess = true;
		try {
			response = await PUT(`${URL_WEIGHT.WEIGHT_WOOD_PLOT_TRANSACTION}${data.id}/`, data);
		} catch (error) {
			response = { errorMessages: `[${data.id}] ${error.errorMessages}` }
			isSuccess = false;
		}
		return [isSuccess, response];
	}

	React.useEffect(() => {
		if (props.data) {
			setPlots(props.data)
		}
	}, [props.data])

	React.useEffect(() => {
		if (props.productName) {
			let name = props.productName.toLowerCase()
			setEnableKnife((name.includes('cuplump') || name.includes('sheet')) ? true : false)
		}
	}, [props.productName])

	return (
		<div>
			<Divider horizontal style={{marginTop: 24, marginBottom: 24}}>{"แปลงสวนยาง"}</Divider>
			{errorMessage && (
				<Message error 
					header={t("messages.error")}
					content={errorMessage}/>
			)}

			{plots.map((item, index) => {
				return (
					<PlotItem 
						key={index}
						ref={el => (plotRef.current[index] = el)}
						formId={props.formId}
						item={item} 
						enableKnife={enableKnife}/>
				)
			})}
			
			{/* <div style={{textAlign: "right"}}>
				<Button 
					color={"teal"} 
					onClick={handleSave}>
					<Icon name={"save"}/>{"บันทึกแปลงสวนยาง"}
				</Button>
			</div> */}
		</div>
	)
})

WoodPlotCard.defaultProps = {
	data: [],
	product: "",
	formId: null,
	onSaved: () => {}
}

WoodPlotCard.propTypes = {
	data: propTypes.array,
	productName: propTypes.string,
	formId: propTypes.string,
	onSaved: propTypes.func
}

export default React.memo(WoodPlotCard);