export const AUTH_TOKEN = {
	token_key: "changman_token",
	user_key: "changman_user"
}

export const USER_GROUP = {
	COMPANY_MANAGER: "company_manager",
}

export const PRODUCT_UNIT = {
	gram: "กรัม",
	kilo_gram: "กิโลกรัม",
	tonne: "ตัน",
}

export const VEHICLE_TYPE = {
	truck: "รถบรรทุก",
	pickup: "รถกระบะ",
	other: "อื่นๆ",
}

export const COLOR_SET = {
	blue: {
		code: "#2185d0",
    lineColor: "rgba(33, 133, 208, 1)",
    backgroundColor: "rgba(33, 133, 208, 0.2)",
  },
  yellow: {
		code: "#fbbd08",
    lineColor: "rgba(251, 189, 8, 1)",
    backgroundColor: "rgba(251, 189, 8, 0.2)",
  },
  teal: {
		code : "#00b5ad",
    lineColor: "rgba(0, 181, 173, 1)",
    backgroundColor: "rgba(0, 181, 173, 0.2)",
  },
  green: {
		code: "#21ba45",
    lineColor: "rgba(33, 186, 69, 1)",
    backgroundColor: "rgba(33, 186, 69, 0.2)",
  },
  orange: {
		code: "#f26202",
    lineColor: "rgba(242, 113, 28, 1)",
    backgroundColor: "rgba(242, 113, 28, 0.2)",
	},
	olive: {
		code: "#d9e778",
		lineColor: "rgba(181, 204, 24, 1)",
		backgroundColor: "rgba(181, 204, 24, 0.2)",
	},
	black: {
		code: "#000000",
		lineColor: "rgba(0, 0, 0, 1)",
		backgroundColor: "rgba(0, 0, 0, 0.2)",
	},
	grey: {
		code: "#767676",
		lineColor: "rgba(118, 118, 118, 1)",
		backgroundColor: "rgba(118, 118, 118, 0.2)",
	},
	white: {
		code: "#ffffff",
		lineColor: "rgba(255, 255, 255, 1)",
		backgroundColor: "rgba(255, 255, 255, 0.8)",
		noneColor: "rgba(255, 255, 255, 0)",
	}
}