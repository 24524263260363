import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Icon, Message, List, Dimmer, Loader, Form, Button, Divider } from 'semantic-ui-react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { GET, PATCH, POST } from '../utils/HttpClient';
import { URL_AUTH } from "../constances/urls";
import { PATH } from '../routes/Routes';
import { MOBILE_WIDTH } from "../Config";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Toast } from "../utils/Toast";
import ChangePasswordModal from "../components/common/ChangePasswordModal";
import InputModal from "../components/common/InputModal";
import ConfirmModal from "../components/common/ConfirmModal";
import { AUTH_TOKEN } from '../constances/string';

// const { t, i18n } = useTranslation();
const ItemDetail = ({title, icon, detail, onEdit}) => {
  const isMobile = (window.innerWidth <= MOBILE_WIDTH);
  return (
    <List.Item style={{padding: 18}}>
      <List.Content floated={"left"}>
        <Icon size={isMobile ? "" : "large"} name={icon}/>
        <label style={{fontSize: isMobile ? 16 : 18, marginLeft: 8}}>{title}</label>
      </List.Content>
      <List.Content floated={"right"}>
        <label style={{fontSize: isMobile ? 16 : 18, marginRight: (onEdit) ? 16 : 0}}>{detail}</label>
        {onEdit && (
          <Icon name={"edit"} onClick={onEdit}/>
        )}
      </List.Content>
    </List.Item>
  )
}

const ItemSelect = ({title, icon, onClick=() => {}}) => {
  const isMobile = (window.innerWidth <= MOBILE_WIDTH);
  return (
    <List.Item 
      style={{padding: 18}}
      onClick={onClick}>
      <List.Content floated={"left"}>
        <Icon size={isMobile ? "" : "large"} name={icon}/>
        <label style={{fontSize: isMobile ? 16 : 18, marginLeft: 8}}>{title}</label>
      </List.Content>
      <List.Content floated={"right"}>
        <Icon name={"chevron right"}/>
      </List.Content>
    </List.Item>
  )
}

export default function ProfileView({ shiftReady }) {
  const isMobile = (window.innerWidth <= MOBILE_WIDTH);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const [detail, setDetail] = useState(null);
  const [signOutConfirmVisible, setSignOutConfirmVisible] = useState(false);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [changeEmailVisible, setChangeEmailVisible] = useState(false);
  const [changeNameVisible, setChangeNameVisible] = useState(false);
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t("required")),
    last_name: Yup.string().required(t("required")),
  });
  const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
    initialValues: detail 
      ? {first_name: detail.first_name, last_name: detail.last_name}
      : {first_name: "", last_name: ""},
		validationSchema: validationSchema,
		onSubmit: (values) => handleSubmitName(values),
  });

  const onSignOut = async () => {
    setSignOutConfirmVisible(false);

    Cookies.remove(AUTH_TOKEN.token_key);
		Cookies.remove(AUTH_TOKEN.user_key);
    history.push(PATH.LOGIN);
  };

  const fetchUserProfile = async () => {
    setLoading(true);
    try {
      const response = await GET(URL_AUTH.USERS_ME);
      setDetail(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  };

  const updateProfile = async (data) => {
    if (!detail || !detail.id)
      return;
    
    setLoading(true);
    try {
      const id = detail.id;
      const response = await PATCH(`${URL_AUTH.USERS}${id}/`, data);
      Toast.success(t("profiles.profile_updated_successfully"));
      setErrorMessages(null);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
      fetchUserProfile();
    }
  };

  const handleSubmitName = (values) => {
    setChangeNameVisible(false);
    updateProfile(values);
  };

  // Change password
  const handleChangePassword = async (values) => {
    if (!values || !detail)
      return;
    
    setLoading(true);
    const data = {...values, username: detail.username}
    try {
      const response = await POST(URL_AUTH.USERS_CHANGE_PASSWORD, data);
      Toast.success(t("profiles.profile_updated_successfully"));
      setErrorMessages(null);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
      fetchUserProfile();
    }
  }

  const onHardRefreshPage = () => {
    caches.keys().then(names => {
      for (let name of names) {
        caches.delete(name)
      }
      // hard reset to reload all caches
      window.location.reload(true);
    })
  }

  useEffect(() => {
    if (!changeNameVisible) {
      formik.resetForm();
    }
  }, [changeNameVisible]);

  useEffect(() => { 
    fetchUserProfile();
  }, []);

  return (
    <div>
      <Header dividing style={{textAlign: "left", padding: "8px"}}>
        {t('menu.profile')}
      </Header>

      <Dimmer active={loading}>
				<Loader />
			</Dimmer>
      { errorMessages && (
        <Message negative>
          <Message.Header>{t("error")}</Message.Header>
          <p>{errorMessages}</p>
        </Message>
      )}

      {/* Sign-Out */}
      <ConfirmModal 
        title={t("profiles.sign_out")}
        detail={t("profiles.are_you_sure_that_you_want_to_sign_out")}
        open={signOutConfirmVisible}
        onClose={() => setSignOutConfirmVisible(false)}
        onCancel={() => setSignOutConfirmVisible(false)}
        onConfirm={onSignOut}/>

      {/* Change password */}
      <ChangePasswordModal 
        open={changePasswordVisible}
        onClose={() => setChangePasswordVisible(false)}
        onConfirm={(values) => {
          setChangePasswordVisible(false);
          handleChangePassword(values);
        }}/>

      {/* Change email */}
      <InputModal 
				open={changeEmailVisible}
				title={t("profiles.email")}
				initialValue={detail ? detail.email : ""}
				onClose={() => setChangeEmailVisible(false)}
				onCancel={() => setChangeEmailVisible(false)}
				onConfirm={(value) => {
          if (value === "")
            return;

          setChangeEmailVisible(false);
          updateProfile({email: value});
        }}/>

      {/* Change name */}
      <ConfirmModal 
        title={t("profiles.name")}
        detail={
          <Form>
            <Form.Group widths={"equal"}>
              <Form.Input 
                fluid
                required
                name={"first_name"}
                label={t("profiles.first_name")}
                value={formik.values.first_name}
                error={formik.errors.first_name}
                onChange={formik.handleChange}/>
              <Form.Input
                fluid
                required
                name={"last_name"}
                label={t("profiles.last_name")}
                value={formik.values.last_name}
                error={formik.errors.last_name}
                onChange={formik.handleChange}/>
            </Form.Group>
          </Form>
        }
        open={changeNameVisible}
        onClose={() => setChangeNameVisible(false)}
        onCancel={() => setChangeNameVisible(false)}
        onConfirm={formik.handleSubmit}/>
      <Button
        floated="right"
        icon="sync"
        content={t("refresh")}
        basic color="black"
        onClick={onHardRefreshPage}
      />
      <Divider hidden clearing/>
      <div 
        style={{
          flex: 1, 
          display: "flex",
          justifyContent: "center",
          alignItems: "center"}}>
        <List 
          divided 
          verticalAlign={"middle"}
          style={{width: "95%", textAlign: "center"}}>

          <ItemDetail 
            title={t("profiles.username")}
            icon={"user"}
            detail={detail ? detail.username : ""}/>

          <ItemDetail 
            title={t("profiles.name")}
            icon={"id card"}
            detail={detail ? detail.display_name : ""}
            onEdit={() => setChangeNameVisible(true)}/>

          <ItemDetail 
            title={t("profiles.email")}
            icon={"mail"}
            detail={detail ? detail.email : ""}
            onEdit={() => setChangeEmailVisible(true)}/>

          <ItemSelect 
            title={t("profiles.change_password")}
            icon={"sync alternate"}
            onClick={() => setChangePasswordVisible(true)}/>

          <ItemSelect 
            title={t("profiles.sign_out")}
            icon={"unlock"}
            onClick={() => {
              if (shiftReady) {
                Toast.warning(t("shifts.warning_on_shift"));
              } else {
                setSignOutConfirmVisible(true)
              }
            }}/>

        </List>
      </div>
    </div>
  )
}

ProfileView.defaultProps = {}

ProfileView.propTypes = {}