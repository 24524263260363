import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DatesRangeInput, DateInput } from 'semantic-ui-calendar-react';
import { Form, Grid, Checkbox } from 'semantic-ui-react';
import { URL_REPORT } from '../../constances/urls';
import ReportExportButton from '../common/ReportExportButton';


export default function AllProductSummary() {

  const { t } = useTranslation();
  const [isDateRange, setIsDateRange] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [dateRange, setDateRange] = React.useState('');
  const [dateRangeError, setDateRangeError] = React.useState(null);
  const [reportParams, setReportParams] = React.useState({});
  const reportRef = React.useRef();

  React.useEffect(() => {
    if (Object.keys(reportParams).length > 0) {
      reportRef.current.generateReport();
    }
  }, [reportParams])

  const validateData = () => {
    if (isDateRange) {
      const [date_after, date_before] = dateRange.split(' - ');
      if (
        (moment(date_after, 'YYYY-MM-DD').isValid()) &&
        (moment(date_before, 'YYYY-MM-DD').isValid())
      ) {
        return { date_after, date_before }
      }
      return false
    }
    else {
      return selectedDate != null
    }
  }

  const handlePrintReport = () => {
    setDateRangeError(null);
    const dateValidator = validateData();
    if (!dateValidator) {
      setDateRangeError('invalid date');
    }
    else {
      setReportParams({
        date_after: isDateRange ? dateValidator.date_after : selectedDate,
        date_before: isDateRange ? dateValidator.date_before : selectedDate,
      })
    }
  }

  return (
    <div>
    <Form>
      <Form.Field>
      <Checkbox 
        toggle 
        checked={isDateRange}
        onChange={(e, data) => setIsDateRange(data.checked)}
        label={isDateRange ? t('date_range') : t('date_time')} 
      />
      </Form.Field>
      {isDateRange && 
          <Form.Field
            control={DatesRangeInput}
            autoComplete="off"
            name='dateRange'
            placeholder={t('date_range')}
            dateFormat='YYYY-MM-DD'
            value={dateRange}
            error={dateRangeError}
            onChange={(_, { name, value }) => {
              setDateRange(value)
            }}
          />
      }
      {!isDateRange &&
          <Form.Field
            control={DateInput}
            maxDate={moment()}
            dateFormat='YYYY-MM-DD'
            value={selectedDate}
            error={dateRangeError}
            onChange={(_, data) => {
              console.log(data.value)
              setSelectedDate(data.value)
            }}
            animation='false'
          />
      }
    </Form>
      <Grid style={{'margin-top': '10px'}}>
        <Grid.Row textAlign='center'>
          <Grid.Column>
            <ReportExportButton
              ref={reportRef}
              url={`${URL_REPORT.PRODUCT_ALL_REPORT}`}
              onClick={handlePrintReport}
              params={reportParams}
              size="medium"
              title={t('report.print_report')}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}
