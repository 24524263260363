import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Header, Popup } from 'semantic-ui-react';
import { URL_LOG } from '../../constances/urls';
import { GET } from '../../utils/HttpClient';
import { ellipsisString, formatComma } from '../../utils/Util';
import ListTable from '../common/ListTable';


export default function DeletedTransaction() {

  const { t, i18n } = useTranslation();

  const columns = useMemo(() => [
    {
      Header: t('scales.date'),
      accessor: 'created'
    },
    {
      Header: t('scales.ticket'),
      accessor: 'code'
    },
    {
      Header: t('vehicles.license_plate'),
      accessor: 'vehicle_license_plate',
    },
    {
      Header: t('logs.weight_form.amount'),
      accessor: 'amount',
      Cell: ({row}) => `${formatComma(row.original.amount)} ${t('units.baht')}`
    },
    {
      Header: t('memo'),
      accessor: 'memo',
      Cell: ({row}) => (
        <Popup
          inverted
          trigger={<label>{ellipsisString(row.original.memo, 20)}</label>}
          content={row.original.memo}
          position='bottom right'
        />
      )
    },
  ], []);

  const [data, setData] = useState({
    links: {
      next: null,
      previous: null,
    },
    count: 0,
    pages: 0,
    results: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(null);
  const tableRef = useRef();

  const fetchData = async (params) => {
    if (!params && tableRef.current) {
      params = tableRef.current.getParams();
    }
    setIsLoading(true);
    setErrorMessages(null)
    try {
      const response = await GET(URL_LOG.DELETED_TRANSACION, params)
      setData(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      { data.count > 0 &&
        <div>
          <Divider hidden />
          <Header textAlign='left'>{t('report.deleted_transaction')}</Header>
          <Divider></Divider>
          <ListTable
            ref={tableRef}
            data={data}
            columns={columns}
            refetch={fetchData}
            loading={isLoading}
            error={errorMessages}
            showPagination
          />
        </div>
      }
    </div>
  );
}