import React from 'react';
import propTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { Form, Input, Dropdown, Label } from 'semantic-ui-react';
import { GET } from "../../utils/HttpClient";
import { URL_CUSTOMER } from "../../constances/urls";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Toast } from '../../utils/Toast';


const unitOptions = (t) => [
  {key: 1, value: "gram", text: t("units.gram")},
  {key: 2, value: "kilo_gram", text: t("units.kilo_gram")},
  {key: 3, value: "tonne", text: t("units.tonne")},
]

const ProductCard = React.forwardRef((props, ref) => {
	const { t, i18n } = useTranslation();
	const [model, setModel] = React.useState(defaultInitialModel)
	// For search
	const [loading, setLoading] = React.useState(false);
	const [productList, setProductList] = React.useState([]);

	const validationSchema = Yup.object().shape({
		title: Yup.string().required(t("required")),
		price_per_unit: Yup.string().required(t("required")),
		unit: Yup.string().required(t("required")),
		is_fsc: Yup.bool(),
		price_gross_weight: Yup.string()
		.when("is_fsc", {
			is: true,
			then: Yup.string().required(t("required")),
		}),
		drc: Yup.string()
			.when("is_fsc", {
				is: true,
				then: Yup.string().required(t("required")),
			}),
	});
	const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: model,
		validationSchema: validationSchema,
	});

	const handleFocus = (event) => event.target.select();

	React.useImperativeHandle(ref, () => ({
		formik,
		initial: (data) => {
			if (data) {
				setModel({
					...data,
					title: `${data.name}`,
					description: "",
				});
			} else {
				setModel(defaultInitialModel);
			}
		}
	}));

	const getProductList = async () => {
		setLoading(true);

		try {
			const params = {
				is_active: true,
				page_size: 100,
			}
			const response = await GET(URL_CUSTOMER.PRODUCT, params);
			setProductList(
				response.data.results.map(product => {
					return {
						...product,
						key: product.id,
						text: `${product.name} - ${product.price_per_unit} ${t(`units.baht`)} / ${t(`units.${product.unit}`)}`,
						value: {
							...product,
							title: `${product.name}`,
						}
					}
				})
			);
		} catch (error) {
			Toast.error(error.errorMessages)
		} finally {
			setLoading(false);
		}
	}

	const calculateDrc = (price_gross_weight) => {
		const unit_price = parseFloat(formik.values.price_per_unit).toFixed(2)
		const gross_price = parseFloat(price_gross_weight).toFixed(2)
		return ((gross_price * 100) / unit_price).toFixed(2);
	}

	const calculateGrossPrice = (drc) => {
			const unit_price = parseFloat(formik.values.price_per_unit).toFixed(2)
			return ((unit_price * parseFloat(drc).toFixed(2)) / 100).toFixed(2);
	}

	React.useEffect(() => {
		if (formik && formik.values.price_gross_weight) {
			// Calculate %DRC
			formik.setFieldValue("drc", calculateDrc(formik.values.price_gross_weight));
		}
	}, [formik.values.price_per_unit]);


	React.useEffect(() => {
		getProductList();
	}, []);
	
  return (
		<div style={{width: "100%", textAlign: "left"}}>
			<Form loading={loading}>
				<Form.Field
					required
					search
					selection
					name="title"
					label={t("product")}
					placeholder={`${t("select")}...`}
					options={productList}
					onChange={(_, data) => {
						setModel(data.value);
						props.onProductChange(data.value);
					}}
					selectOnBlur={false}
					value={formik.values.title}
					text={formik.values.title}
					error={formik.errors.title}
					control={Dropdown}/>
				
				{/* Product detail */}
				<Form.Group widths={"equal"}>
					<Form.Field
						width={2}
						required
						fluid
						error={formik.errors.price_per_unit}>
						<label>{t("products.price_per_unit")}</label>
						<Input
							name="price_per_unit"
							label={{ basic: true, content: 'บาท/กก.' }}
							labelPosition='right'
							value={formik.values.price_per_unit}
							onFocus={handleFocus}
							onChange={formik.handleChange}
						/>
					</Form.Field>

					{/* <Form.Field
						width={1}
						required
						fluid
						selection
						options={unitOptions(t)}
						label={`${t("products.unit")}`}
						text={formik.values.unit ? t(`units.${formik.values.unit}`) : ""}
						value={formik.values.unit}
						error={formik.errors.unit}
						onChange={(_, data) => formik.setFieldValue('unit', data.value)}
						control={Dropdown}/> */}
					
					{formik.values.need_drc && (
						<Form.Field 
							width={2}
							required
							fluid
							error={formik.errors.price_gross_weight}>
							<label>{t("products.price_gross_weight")}</label>
							<Input
								name="price_gross_weight"
								label={{ basic: true, content: 'บาท/กก.' }}
								labelPosition='right'
								value={formik.values.price_gross_weight}
								onFocus={handleFocus}
								onChange={(_, data) => {
									formik.setFieldValue('price_gross_weight', data.value)
									formik.setFieldValue('drc', calculateDrc(data.value))
								}}
							/>
						</Form.Field>
					)}

					{formik.values.need_drc && (
						<Form.Field
							width={2}
							fluid>
							<label>%DRC</label>
							<Input
								name="drc"
								label={{ basic: true, content: '%' }}
								labelPosition='right'
								value={formik.values.drc}
								onFocus={handleFocus}
								onChange={(_, data) => {
									formik.setFieldValue('drc', data.value)
									formik.setFieldValue('price_gross_weight', calculateGrossPrice(data.value))
								}}
							/>
						</Form.Field>
					)}
				</Form.Group>
			</Form>
		</div>
	)
})

export const defaultInitialModel = {
	id: null,
	name: "",
	code: "",
	title: "",
	price_per_unit: "",
	unit: "kilo_gram",
	is_fsc: false,
	need_drc: false,
	price_gross_weight: 0,
	drc: 0,
};

ProductCard.defaultProps = {
	style: null,
	onProductChange: (product) => {}
}

ProductCard.propTypes = {
	style: propTypes.object,
	onProductChange: propTypes.func
}

export default React.memo(ProductCard);