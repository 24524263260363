import React, { useState, useEffect } from 'react';
import { useTable } from 'react-table';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Header, Grid, Button, Form, Icon, Table, Pagination, Message, Checkbox, Select, Input, Dimmer, Loader } from 'semantic-ui-react';
import { GET } from "../utils/HttpClient";
import { URL_COMPANY, URL_WEIGHT } from "../constances/urls";
import { DateInput } from 'semantic-ui-calendar-react';
import FormScalesModal from "../components/scale/FormScalesModal";
import { formatComma } from "../utils/Util";
import Cookies from "js-cookie";

const searchOptions = (t) => {
	return [
		{ key: 1, value: "all", text: `${t("scales.search_options.all")}` },
		{ key: 2, value: "unfinished", text: `${t("scales.search_options.unfinished")}` },
		{ key: 3, value: "finished", text: `${t("scales.search_options.finished")}` },
	]
}

export default function FormScalesView({ shiftReady }) {
	const { t, i18n } = useTranslation();
	const [openModal, setOpenModal] = useState(false);
	const [selectForm, setSelectForm] = useState(null);
	// Search
	const [isFilterInActive, setFilterInActive] = useState(false);
	const [isFilterDate, setFilterDate] = useState(true);
	const [date, setDate] = useState(moment().format("DD/MM/YYYY"));
	const [searchStatus, setSearchStatus] = useState("unfinished");
	const searchRef = React.useRef(null);
	// Table
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [data, setData] = useState([]);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalPages, setTotalPage] = useState(1);
	const changePurchase = Cookies.get("set_purchase");
	const columns = React.useMemo(() => [
		{
			Header: `${t("scales.date")}`,
			Cell: ({row}) => {
				let date = new Date(moment(row.original.created, "YYYY-MM-DD HH:mm:ss"));
				return (
					<div>
						{date ? `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}` : ""}
					</div>
				);
			}
		},
    {
      Header: `${t("scales.license_plate")}`,
      accessor: 'license_plate',
    },
    {
      Header: `${t("scales.vehicle_type")}`,
			Cell: ({row}) => {
				if (row.original.vehicle_type !== "") {
					return row.original.vehicle_type.split(", ").map(type => t(`vehicle_type.${type}`)).join(", ")
				}
				return ""
			}
    },
    { 
			Header: `${t("customer")}`,
			accessor: 'customer_name',
		},
		{
			Header: `${t("product")}`,
			accessor: "product",
		},
		{
			Header: `${t("scales.weigh_out")}`,
			Cell: ({row}) => {
				const isFinished = row.original.is_finished
				return (
					<Icon circular inverted 
						size={"small"}
						name={isFinished ? "check" : "ellipsis horizontal"} 
						color={isFinished ? "green" : "grey"}/>
				)
			}
		},
		{
			Header: `${t("scales.net_weight")}`,
			accessor: "net_weight",
			Cell: ( {value} ) => {
				return (<div>{formatComma(value)}</div>)
			}
		},
		{
			Header: `${t("scales.net_price")}`,
			accessor: "net_price",
			Cell: ( {value} ) => {
				return (<div>{formatComma(value)}</div>)
			}
		},
		{
			Header: `${t("scales.net_amount")}`,
			accessor: "net_amount",
			Cell: ( {value} ) => {
				return (<div>{(value > 0) ? formatComma(value) : "-"}</div>)
			}
		},
		{
			Header: `${t("cashier")}`,
			Cell: ({row}) => {
				const isPaid = row.original.is_paid
				return (
					<Icon circular inverted 
						size={"small"}
						name={isPaid ? "check" : "ellipsis horizontal"} 
						color={isPaid ? "green" : "grey"}/>
				)
			}
		},
	], []);
	
	const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
    columns,
    data: (data) ? data : [],
	initialState: {
		hiddenColumns: (changePurchase === "true") ? "" : ["net_amount"] 
	}
    },
	);

	const fetchChangePurchase = async() => {
		try {
			const response = await GET(URL_COMPANY.COMPANY);
			if (response.data.results.length > 0) {
				Cookies.set("set_purchase", response.data.results[0].set_purchase);
			}
		} catch (error) {
		  setErrorMessage(error.errorMessages);
		}
	}

	const loadFormList = async (page) => {
		setIsLoading(true);
		if (!page) {
			page = 1;
		}
		let params = {
			page: page,
			page_size: pageSize,
			is_active: !isFilterInActive,
			status: (searchStatus === "all") ? "" : searchStatus,
			lookup_txt: searchRef ? searchRef.current.inputRef.current.value : "",
		}
		if (!isFilterDate && date) {
			const [day, month, year] = date.split("/")
			params.date = `${year}-${month}-${day}`;
		}

		try {
			const response = await GET(URL_WEIGHT.FORM, params);
			let target = response.data;
			setData(target.results);
			setTotalPage(Math.ceil(target.count/pageSize));
			if (errorMessage) {
				setErrorMessage(null);
			}
		} catch(error) {
			console.log("Error: ", error);
			setErrorMessage(error.errorMessages);
		} finally {
			setIsLoading(false);
		}
	}

	const handleAddTransaction = (event, data) => {
		setOpenModal(true);
	}

	const handleSelectForm = (item) => {
		setSelectForm(item.id);
		// setOpenModal(true);
	}

	const handleLoadDataStateChange = () => {
		if (page != 1) {
			setPage(1)
		}
		loadFormList();
	}

	useEffect(() => {
		fetchChangePurchase()
	},[])

	useEffect(() => {
		loadFormList(page);
	}, [page])

	useEffect(() => {
		handleLoadDataStateChange()
	}, [pageSize, isFilterDate, date, searchStatus, isFilterInActive])

	useEffect(() => {
		if (selectForm) {
			setOpenModal(true);
		}
	}, [selectForm]);

  return (
		<div>
			<Header dividing style={{textAlign: "left", padding: "8px"}}>
				{t("menu.scales")}
			</Header>
			<Dimmer inverted active={isLoading}>
				<Loader inverted>{`${t("loading")}...`}</Loader>
			</Dimmer>

			{ errorMessage && 
        <Message negative style={{textAlign: "left"}}>
          <Message.Header>{t("error")}</Message.Header>
          <p>{errorMessage}</p>
        </Message> 
      }

			{/* <div style={{position: "absolute"}}>
				<Checkbox 
					label={t("scales.every_day")}
					defaultChecked
					onClick={( _, data) => {
						setFilterDate(data.checked)
					}}
					value={isFilterDate ? 1 : 0}/>
			</div> */}
			
			<Grid >
				<Grid.Row>
					<Grid.Column width={3} style={{textAlign: "left"}}>
							<Checkbox 
								label={t("scales.every_day")}
								defaultChecked
								onClick={( _, data) => {
									setFilterDate(data.checked)
								}}
								value={isFilterDate ? 1 : 0}/>
					</Grid.Column>
					<Grid.Column width={4} style={{textAlign: "left"}}>
						<Checkbox 
								style={{marginLeft: 24}}
								label={t("deleted_data")}
								onClick={( _, {checked}) => setFilterInActive(checked)}
								value={isFilterInActive ? 1 : 0}/>
					</Grid.Column>

					{/* <Grid.Column width={16}>
						<Button primary 
							floated={"right"}
							content={"Agriac"} 
							icon={"sync alternate"}
							onClick={() => {}}/>
					</Grid.Column> */}
				</Grid.Row>

				<Grid.Row columns='2' style={{marginTop: "32px"}}>
				<Grid.Column floated='left' width={13}>
					<Form>
						<Form.Group inline>
							<Form.Field disabled={isFilterDate}>
								<label>{t("scales.date")}</label>
							</Form.Field>

							<Form.Field
								required
								maxDate={moment()}
								dateFormat='DD/MM/YYYY'
								value={date}
								disabled={isFilterDate}
								readOnly={false}
								onChange={(_, data) => setDate(data.value)}
								animation={"false"}
								control={DateInput}
							/>
							
							<Form.Field>
								<label>{t("scales.status")}</label>
								<Select 
									options={searchOptions(t)}
									onChange={( _, data) => setSearchStatus(data.value)}
									value={searchStatus ? searchStatus : "all"}/>
							</Form.Field>       

							<Form.Field width={4}>
								<Input
									ref={searchRef}
									action={{
										icon: "search",
										onClick: () => handleLoadDataStateChange() 
									}}
									placeholder={`${t("scales.search")}...`}
								/>
							</Form.Field>

							<Button
								color='blue'
								disabled={isLoading}
								icon='redo'
								onClick={() => handleLoadDataStateChange()}>
							</Button>   
						</Form.Group>
					</Form>
				</Grid.Column>
				<Grid.Column floated='right' width={3}>
					{ shiftReady ? (
						<Button 
							color='green' 
							floated='right' 
							icon={"add circle"} 
							content={t("scales.add_form")} 
							onClick={handleAddTransaction}/>
					): (
						<Button
							floated='right'
							icon='ban'
							content={t("shifts.not_ready")}/>
					)}
				</Grid.Column>
				</Grid.Row>
			</Grid>

			{/* T A B L E */}
			<Table {...getTableProps()}>
        <Table.Header>
          {headerGroups.map(headerGroup => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Table.HeaderCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <Table.Row {...row.getRowProps()} onClick={() => handleSelectForm(row.original)}>
                {row.cells.map(cell => {
                  return <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
        { data.length === 0 && !isLoading && 
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='100'> 
                {/* <Message icon='file outline' header='ว่างเปล่า' content="ไม่พบข้อมูล"/> */}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        }
      </Table>
      <Pagination
        activePage={page}
        onPageChange={(e, {activePage}) => setPage(activePage)}
        totalPages={totalPages}
      />

			<FormScalesModal 
				open={openModal}
				onClose={() => {
					setOpenModal(false);
					setSelectForm(null);
					loadFormList(page);
				}}
				shiftReady={shiftReady}
				formUID={selectForm}/>
		</div>
	)
}

FormScalesView.defaultProps = {}

FormScalesView.propTypes = {}