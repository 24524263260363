import React from 'react';
import propTypes from "prop-types";
import {Icon, List} from 'semantic-ui-react';


export default function DetailItem(props) {
  return (
		<List.Item style={{...props.style}} onClick={props.onClick}>
      <List.Content floated={"left"}>
				{props.titleIcon && (
					<Icon name={props.titleIcon}/>
				)}
				<label 
					style={{
						fontSize: props.fontSize, 
						marginLeft: (props.titleIcon) ? 8 : 0
					}}>
					{props.title}
				</label>
      </List.Content>
      <List.Content floated={"right"}>
				<label 
					style={{
						fontSize: props.fontSize, 
						marginRight: (props.onEdit) ? 16 : 0}}>{props.detail}</label>
				{props.onEdit && (
					<Icon name={"edit"} onClick={props.onEdit}/>
				)}
      </List.Content>
    </List.Item>
	)
}

DetailItem.defaultProps = {
	style: null,
	title: "",
	titleIcon: null,
	detail: "",
	fontSize: 18,
	onEdit: null,
	onClick: () => {},
}

DetailItem.propTypes = {
	style: propTypes.object,
	title: propTypes.string,
	titleIcon: propTypes.string,
	detail: propTypes.string,
	fontSize: propTypes.number,
	onEdit: propTypes.func,
	onClick: propTypes.func,
}