import React, { useState, useEffect, useMemo } from 'react';
import { DELETE, GET } from '../../utils/HttpClient';
import { URL_CUSTOMER, URL_WEIGHT } from "../../constances/urls";
import ListTable from '../common/ListTable';
import { Button, Header, Icon, List, Message } from 'semantic-ui-react';
import CustomerDetailModal from './CustomerDetailModal';
import CustomerWalletModal from './CustomerWalletModal';
import ConfirmModal from '../common/ConfirmModal'
import { useTranslation } from 'react-i18next';
import { Toast } from "../../utils/Toast";

export default function CustomerSetting () {
  
  const { t, i18n } = useTranslation();
  const [data, setData] = useState({
    count: 0,
    pages: 0,
    links: {
      next: null,
      previous: null,  
    },
    results: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [isWalletVisible, setIsWalletVisible] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [relatedFormList, setRelatedFormList] = useState({
    count: 0,
    pages: 0,
    links: {
      next: null,
      previous: null,  
    },
    results: [],
  });
  const tableRef = React.useRef();

  const columns = useMemo(() => [
    {
      Header: t('customers.code'),
      accessor: 'code',
    },
    {
      Header: t('customers.name'),
      accessor: 'name',
    },
    {
      Header: t('customers.address'),
      accessor: 'full_address_th',
      Cell: cell => cell.row.original.address? `${cell.row.original.full_address_th}` : <div style={{textAlign: 'center'}}>-</div>
    },
    { Header: '',
      accessor: 'id',
      Cell: cell => {
        const isActive = cell.row.original.is_active;
        const isFsc = cell.row.original.is_fsc;
        return (
        <div style={{textAlign: 'center'}} >
          {isActive && (
            <div>
              <Button color='blue' basic onClick={() => editCustomer(cell.row.original, false)}>
                <Icon name='edit'/>
                {t('edit')}
              </Button>
              <Button color='red' basic onClick={() => {deleteCustomer(cell.row.original)}}>
                <Icon name='delete'/>
                {t('delete')}
              </Button>
            </div>
          )}
          {!isActive && (
            <Button color='blue' basic onClick={() => {reactivateCustomer(cell.row.original)}}>
              <Icon name='sync alternate'/>
              {t('reactivate')}
            </Button>
          )}
        </div>
      )}
    },
  ], []);

  const fetchData = async (params) => {
    if (!params) {
      params = tableRef.current.getParams();
    }
    setIsLoading(true);
    setErrorMessage(null);
    try {
      const response = await GET(URL_CUSTOMER.CUSTOMER, params);
      setData(response.data);
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleDeleteCustomer = async () => {
    setIsLoading(true);
    setErrorMessage(null);
    setIsDeleteVisible(false);
    try {
      const response = await DELETE(`${URL_CUSTOMER.CUSTOMER}${selectedCustomer.id}/`)
      Toast.success(`${t('delete')} ${t('messages.success')}`);
      fetchData();
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const createCustomer = () => {
    setIsDetailVisible(true);
    setSelectedCustomer(null);
  }

  const editCustomer = (customer, readOnly) => {
    setReadOnly(readOnly);
    setIsDetailVisible(true);
    setSelectedCustomer(customer);
  }

  const deleteCustomer = async (customer) => {
    setIsDeleteVisible(true);
    setSelectedCustomer(customer);

    try {
      const params = {
        page_size: 20,
        is_active: true,
        status: 'unfinished',
        customer: customer.id
      }
      const response = await GET(URL_WEIGHT.FORM, params)
      setRelatedFormList(response.data);
    } catch (error) {
      setErrorMessage(error.errorMessages)
    }
  }

  const reactivateCustomer = (customer) => {
    setIsDetailVisible(true);
    setSelectedCustomer(customer);
  }

  const openWallet = (customer) => {
    setIsWalletVisible(true);
    setSelectedCustomer(customer);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <ConfirmModal
        open={isDeleteVisible}
        title={t("customers.delete_customer")}
        detail={
          (
            <div>
              <p>{t("customers.msg_confirm_delete_customer", {name: (selectedCustomer ? selectedCustomer.name : "")})}</p>
              { relatedFormList.count > 0 && 
                <Message warning>
                <Message.Header>{`${t('warnings.form_will_be_deleted')} (${relatedFormList.count} ${t('scales.weight_form')})`}</Message.Header>
                <List>
                  {relatedFormList.results.map((form, index) => (
                    <List.Item>
                      <List.Icon name='angle right'/>
                      <List.Content>
                        <List.Header>{`${t('scales.time')} ${form.created}`}</List.Header>
                        <List.List>
                          <List.Item>
                            <List.Content>{`${t('vehicles.license_plate')} ${form.license_plate || '-'}`}</List.Content>
                          </List.Item>
                          <List.Item>
                            <List.Content>{`${t('product')}: ${form.product || '-'}`}</List.Content>
                          </List.Item>
                        </List.List>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Message>
            }
            </div>
          )
        }
        onClose={() => {
          setIsDeleteVisible(false);
          setSelectedCustomer(null);
        }}
        onConfirm={handleDeleteCustomer}
        onCancel={() => {
          setIsDeleteVisible(false);
          setSelectedCustomer(null);
        }}      
      />
      <CustomerWalletModal
        open={isWalletVisible}
        onClose={() => setIsWalletVisible(false)}
        customer={selectedCustomer}
      />
      <CustomerDetailModal
        open={isDetailVisible}
        readOnly={readOnly}
        onClose={() => setIsDetailVisible(false)}
        customer={selectedCustomer}
        refetch={fetchData}
      />
      <ListTable
        ref={tableRef}
        columns={columns}
        data={data}
        loading={isLoading}
        error={errorMessage}
        onCreate={createCustomer}
        refetch={fetchData}
        showActionHeader
        showPagination
      />
    </div>
  );
}