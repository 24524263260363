import React, { useState } from 'react'
import propTypes from "prop-types";
import old_logo from "../../assets/logo_old.png"
import logo from "../../assets/logo.png"
import { Grid, Message, Image, Button, Segment, Form } from 'semantic-ui-react'
import { POST } from "../../utils/HttpClient";
import { URL_CUSTOMER } from "../../constances/urls";
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Toast } from "../../utils/Toast";


export default function LoginView (props) {
  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

	const validationSchema = Yup.object().shape({
    liff_otp: Yup.string().required(t("required")),
  });
  const formik = useFormik({
		validateOnChange: false,
    validationSchema: validationSchema,
    initialValues: {
      liff_otp: '',
    },
		onSubmit: (values) => onSubmitLogin(values),
  });

	const onSubmitLogin = async (values) => {
		setIsLoading(true);
		setErrorMessage(null);
		const url = `${URL_CUSTOMER.CREATE_LIFF}${values.otp}/${props.liff.getAccessToken()}/`
		try {
			const response = await POST(URL_CUSTOMER.CREATE_LIFF, {
				...values,
				liff_user_id: props.liff.getAccessToken()
			})
			Cookies.set('customer', response.data.id, { expires: 30 })
			props.onLoggedIn()
		} catch (error) {
			setErrorMessage(error.errorMessages);
			Toast.error(error.errorMessages)
		} finally {
			setIsLoading(false);
		}
  };

  return (
    <div className='login-form'>
      <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}
      </style>
      <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
        <Grid.Column
          style={{ 
            maxWidth: 450, 
            display: 'flex', 
            alignItems: "center" 
          }}>
          <Image src={old_logo} size='small' centered />
          <Image src={logo} size='small' centered/>

					<Message info style={{maxWidth: 380}} content={"กรุณากรอก OTP หน้าเครื่องชั่งเพื่อลงทะเบียนลูกค้า"}/>
					<Segment 
              style={{width: "95%"}} >
              <Form onSubmit={formik.handleSubmit} >
                <Message
                  error
                  header={t("profiles.authentication_failed")}
                  content={errorMessage}
                  visible={errorMessage != null}
                />
                <Form.Input
                  fluid
                  name='liff_otp'
                  icon='lock'
                  iconPosition='left'
                  placeholder={"OTP"}
                  value={formik.values.liff_otp}
                  error={formik.errors.liff_otp}
                  onChange={formik.handleChange}
                />
                <Button
                  color='blue'
                  fluid size='large'
                  type='submit'
                  loading={isLoading}
                >
                  {t("profiles.sign_in")}
                </Button>
              </Form>
            </Segment>

        </Grid.Column>
      </Grid>
    </div>
  )
}

LoginView.defaultProps = {
	onLoggedIn: () => null,
  liff: null,
}

LoginView.propTypes = {
	onLoggedIn: propTypes.func,
  liff: propTypes.object,
}