import React from 'react';
import propTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { Checkbox, Grid, Icon, List } from 'semantic-ui-react';
import { Toast } from "../../utils/Toast";
import Cookies from "js-cookie";
import DetailItem from "../common/DetailItem";
import InputModal from "../common/InputModal";
import ConfirmModal from "../common/ConfirmModal";
import { SERVER } from "../../Config";

export default function ClientView(props) {
	const { t, i18n } = useTranslation();
	const [errorMessage, setErrorMessage] = React.useState(null);
	const [server, setServer] = React.useState(null);
	const [port, setPort] = React.useState(null);
	const [autoPrint, setAutoPrint] = React.useState(true);
	const [purchase, setPurchase] = React.useState(true);
	// Modal
	const [openModal, setOpenModal] = React.useState(false);
	const [titleModal, setTitleModal] = React.useState("");
	const [placeholder, setPlaceholder] = React.useState("");
	const [inputType, setInputType] = React.useState("text");
	const [openSwitchModal, setOpenSwitchModal] = React.useState(false);
	const [tempAutoPrint, setTempAutoPrint] = React.useState(true);
	const [tempPurchase, setTempPurchase] = React.useState(true);
	const [openPurchaseModal, setOpenPurchaseModal] = React.useState(false);

	const saveValue = (value) => {
		if (!value || (value === ""))
			return;
		
		switch (titleModal) {
			case t("admin.server_ip"):
				Cookies.set("server", value);
				setServer(value);
				break;
			case t("admin.server_port"):
				Cookies.set("server_port", value);
				setPort(value);
				break;
			default: break;
		}
		Toast.success(t("admin.client_information_updated_successfully"));
	}

	React.useEffect(() => {
		let currentServer = Cookies.get("server");
		if (!currentServer) {
			Cookies.set("server", SERVER.DEFAULT_IP);
			currentServer = SERVER.DEFAULT_IP;
		}
		setServer(currentServer);

		let currentPort = Cookies.get("server_port");
		if (!currentPort) {
			Cookies.set("server_port", SERVER.DEFAULT_PORT);
			currentPort = SERVER.DEFAULT_PORT;
		}
		setPort(currentPort);

		let currentPurchase = Cookies.get("set_purchase");
		if (!currentPurchase) {
			Cookies.set("set_purchase", SERVER.DEFAULT_PURCHASE);
			currentPurchase = SERVER.DEFAULT_PURCHASE;
		}
		
		if (currentPurchase === "true") {
			setPurchase(true);
			setTempPurchase(true);
		} else {
			setPurchase(false);
			setTempPurchase(false);
		}

		let currentAutoPrint = Cookies.get("auto_print");
		if (!currentAutoPrint) {
			Cookies.set("auto_print", SERVER.DEFAULT_AUTO_PRINT);
			currentAutoPrint = SERVER.DEFAULT_AUTO_PRINT;
		}
		
		if (currentAutoPrint === "true") {
			setAutoPrint(true);
			setTempAutoPrint(true);
		} else {
			setAutoPrint(false);
			setTempAutoPrint(false);
		}
		
	}, []);

  return (
		<div>
			<List 
				divided 
        verticalAlign={"middle"}
				style={{marginLeft: 65, marginRight: 65}}>

					<DetailItem 
						style={{padding: 18}}
						title={t("admin.server_ip")}
						titleIcon={"server"}
						detail={server ? server : ""}
						onEdit={() => {
							setTitleModal(t("admin.server_ip"));
							setPlaceholder(server);
							setInputType("text");
							setOpenModal(true);
						}}/>

					<DetailItem 
						style={{padding: 18}}
						title={t("admin.server_port")}
						titleIcon={"sitemap"}
						detail={port ? port : ""}
						onEdit={() => {
							setTitleModal(t("admin.server_port"));
							setPlaceholder(port);
							setInputType("number");
							setOpenModal(true);
						}}/>

					{/* Purchase Setting*/}
					{/* <DetailItem 
						style={{padding: 18}}
						title={t("admin.set_purchase_price")}
						titleIcon={"money bill alternate outline"}
						detail={purchase ? t("enable") : t("disable")}
						onEdit={() => {
							setOpenPurchaseModal(true)
						}}/> */}

					<DetailItem 
						style={{padding: 18}}
						title={t("admin.automatically_print_ticket")}
						titleIcon={"print"}
						detail={autoPrint ? t("enable") : t("disable")}
						onEdit={() => {
							setOpenSwitchModal(true);
						}}/>

					<DetailItem
						as='a'
						style={{ padding: 18, cursor:"pointer" }}
						title={t("admin.download_thin_client")}
						titleIcon={"download"}
						detail={t("download")}
						onClick={() => {
							window.open("/ext-media/changman_setup_1.2.11.exe")
						}}
						onEdit={() => {
							window.open("/ext-media/changman_setup_1.2.11.exe")
						}} />

					<DetailItem
						as='a'
						style={{ padding: 18, cursor: "pointer" }}
						title={t("admin.download_thin_client_win32")}
						titleIcon={"download"}
						detail={t("download")}
						onClick={() => {
							window.open("/ext-media/changman_setup_1.2.11_win32.exe")
						}}
						onEdit={() => {
							window.open("/ext-media/changman_setup_1.2.11_win32.exe")
						}} />
			</List>

			<InputModal 
				open={openModal}
				error={errorMessage}
				title={titleModal}
				initialValue={placeholder}
				inputType={inputType}
				onClose={() => setOpenModal(false)}
				onCancel={() => setOpenModal(false)}
				onConfirm={(value) => {
					saveValue(value);
					setTitleModal("");
					setOpenModal(false);
				}}/>
			
			{/* Auto Print */}
			<ConfirmModal 
				open={openSwitchModal}
				size={"tiny"}
				title={t("admin.automatically_print_ticket")}
				detail={
					<Checkbox 
						toggle 
						label={tempAutoPrint ? t("enable") : t("disable")}
						defaultChecked={tempAutoPrint}
						onChange={(_, {checked}) => setTempAutoPrint(checked)}/>
				}
				onClose={() => {
					setOpenSwitchModal(false);
					setTempAutoPrint(autoPrint);
				}}
				onCancel={() => {
					setOpenSwitchModal(false);
					setTempAutoPrint(autoPrint);
				}}
				onConfirm={() => {
					const value = tempAutoPrint;
					Cookies.set("auto_print", value);
					setAutoPrint(value);
					setOpenSwitchModal(false);
					Toast.success(t("admin.client_information_updated_successfully"))
				}}	
				/>

			{/* Set Purchase */}
			<ConfirmModal 
				open={openPurchaseModal}
				size={"tiny"}
				title={t("admin.set_purchase_price")}
				detail={
					<Checkbox 
						toggle 
						label={tempPurchase ? t("enable") : t("disable")}
						defaultChecked={tempPurchase}
						onChange={(_, {checked}) => setTempPurchase(checked)}/>
				}
				onClose={() => {
					setOpenPurchaseModal(false);
					setTempPurchase(purchase);
				}}
				onCancel={() => {
					setOpenPurchaseModal(false);
					setTempPurchase(purchase);
				}}
				onConfirm={() => {
					const value = tempPurchase;
					Cookies.set("set_purchase", value);
					setPurchase(value);
					setOpenPurchaseModal(false);
					Toast.success(t("admin.client_information_updated_successfully"))
				}}
				/>
		</div>
	)
}

ClientView.defaultProps = {}

ClientView.propTypes = {}