import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { setupServiceWorkerStatusTransfer } from './utils/setupServiceWorkerStatusTransfer';
import { LiffProvider } from 'react-liff-v2';
import { PATH } from './routes/Routes';


const { listen, onUpdate } = setupServiceWorkerStatusTransfer();

function getLiffApp(path) {
  // Split /liff-app/liff-dashboard/ into liff-dashboard
  return path.split('/')[2];
}

function getLiffId() {
  const href = window.location.href;

  if (href.includes(getLiffApp(PATH.LIFF_DASHBOARD))) {
    return '1661097684-jkg14DRv';
  }
  else if(href.includes(getLiffApp(PATH.LIFF_SCALES))) {
    return '1661097684-jar2PRA6';
  }
  else if(href.includes(getLiffApp(PATH.LIFF_TICKET))) {
    return '1661097684-wd16QXoj';
  }
  else {
    return '1661097684-jkg14DRv';  // Default path
  }
}

ReactDOM.render(
  <React.StrictMode>
    <LiffProvider liffId={getLiffId()} 
    // stubEnabled={process.env.NODE_ENV !== 'production'}
    >
      <App listen={listen}/>
    </LiffProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate,
});