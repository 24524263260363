import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid, Header, Icon, List, Message, Segment } from "semantic-ui-react";
import { URL_WEIGHT, URL_COMPANY } from "../constances/urls";
import { PATH } from "../routes/Routes";
import { GET } from "../utils/HttpClient";
import { dateToString, formatComma } from "../utils/Util";
import Cookies from "js-cookie";

const pageSize = 20;

export default function ManagerView() {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const [loading, setLoading] = React.useState(false);
	const [totalPage, setTotalPage] = React.useState(0);
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [data, setData] = React.useState([]);
	const changePurchase = Cookies.get("set_purchase");

	const loadFormList = async (page) => {
		setLoading(true);
		if (!page) {
			page = 1;
		}
		let params = {
			page: page,
			page_size: pageSize,
			is_active: true,
			status: "finished",
			lookup_txt: "",
		}

		try {
			const response = await GET(URL_WEIGHT.FORM, params);
			let target = response.data;
			setData(target.results);
			setTotalPage(Math.ceil(target.count/pageSize));
			if (errorMessages) {
				setErrorMessages(null);
			}
		} catch(error) {
			console.log("Error: ", error);
			setErrorMessages(error.errorMessages);
		} finally {
			setLoading(false);
		}

		try {
			const response = await GET(URL_COMPANY.COMPANY);
			if (response.data.results.length > 0) {
				Cookies.set("set_purchase", response.data.results[0].set_purchase);
			}
		} catch (error) {
		  setErrorMessages(error.errorMessages);
		}
	}

	React.useEffect(() => {
		loadFormList(1);
	}, [])

	return (
		<div>
			{ errorMessages && 
        <Message negative>
          {errorMessages}
        </Message>
      }
      <Header dividing style={{textAlign: "left", padding: "8px"}}>
				{`${t('menu.scales')} (Manager)`}
			</Header>

			<List style={{paddingBottom: 8}}>
				{ data.map((scale, id) => {
						return (
							<List.Item 
								key={`scale-${id}`} 
								style={{marginBottom: 8}} 
								onClick={() => history.push(`${PATH.TICKET_DETAIL}${scale.id}/`)}>
								<Segment>
									<div style={{textAlign: "left"}}>{`${dateToString(scale.created)}`}</div>
									<Grid>
										<Grid.Row>
											<Grid.Column textAlign={"left"} width={11}>
												<div style={{marginTop: 12, fontSize: 16}}>
													<Icon name={"truck"} />
													{` ${scale.license_plate}`}
												</div>
												<div style={{marginTop: 8, fontSize: 16}}>
													<Icon name={"tags"} />
													{` ${t(`vehicle_type.${scale.vehicle_type}`)}`}
												</div>
												<div style={{marginTop: 8, fontSize: 16}}>
													<Icon name={"certificate"} />
													{`${scale.product}`}
												</div>
											</Grid.Column>

											<Grid.Column textAlign={"right"} width={5}>
												<div style={{marginTop: 12}}>{t("scales.weigh")}</div>
												<div style={{marginTop: 4, fontSize: 24, fontWeight: "bold"}}>{`${formatComma(scale.net_weight)}`}</div>
												<div style={{marginTop: 8}}>{t("scales.price")}</div>
												<div style={{marginTop: 4, fontSize: 24, fontWeight: "bold"}}>{`${formatComma(scale.net_price)}`}</div>
												{(changePurchase === "true" ) && <div style={{marginTop: 8}}>{t("scales.net_amount")}</div>}
												{(changePurchase === "true" ) && <div style={{marginTop: 4, fontSize: 24, fontWeight: "bold"}}>{`${(scale.net_amount > 0) ? formatComma(scale.net_amount) : "-" }`}</div>}
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Segment>
							</List.Item>
						)
					}) 
				}                
			</List>
		</div>
	)
}