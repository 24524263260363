import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Message, Dimmer, Loader } from 'semantic-ui-react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { POST, PATCH } from '../../utils/HttpClient';
import { URL_CUSTOMER } from "../../constances/urls";
import { useTranslation } from 'react-i18next';
import { Toast } from "../../utils/Toast";
import propTypes from 'prop-types';
import NumberFormat from 'react-number-format';


const unitOptions = (t) => [
  {key: 'gram', value: 'gram', text: t('units.gram')},
  {key: 'kilo_gram', value: 'kilo_gram', text: t('units.kilo_gram')},
  {key: 'tonne', value: 'tonne', text: t('units.tonne')},
];

export default function ProductDetailModal ({ open, readOnly, onClose, product, refetch}) {

  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessage] = useState(null);
  const [detail, setDetail] = useState(defaultModel);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required')),
    price_per_unit: Yup.string().required(t('required')),
    unit: Yup.string().required(t('required')),
  })
  const formik = useFormik({
    enableReinitialize: true,
		validateOnChange: false,
		initialValues: detail,
		validationSchema: validationSchema,
		onSubmit: (values) => handleSubmitProduct(values),
  })

  useEffect(() => {
    if (product) {
      setDetail(product);
    } else {
      setDetail(defaultModel);
    }
  }, [product]);
	
	useEffect(() => {
		if (open) {
			setErrorMessage(null);
		}

	}, [open])

  const handleSubmitProduct = async (data) => {
    if (parseFloat(data.price_per_unit) <= 0) {
      formik.setErrors({price_per_unit: t('messages.more_than_zero')})
      return;
    }
    setIsLoading(true)

    try {
      const params = data;
      if (product) {
        await PATCH(`${URL_CUSTOMER.PRODUCT}${product.id}/`, params)
      } else {
        await POST(URL_CUSTOMER.PRODUCT, params)
      }
      formik.resetForm();
      handleCloseSuccessModal();
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleReactivateProduct = async () => {
    if (!product) {
      return;
    }

    setIsLoading(true);
    try {
      const productId = product.id;
      const response = await POST(`${URL_CUSTOMER.PRODUCT}${productId}/reactivate/`);
      handleCloseSuccessModal();
    } catch (error) {
      setErrorMessage(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleCloseSuccessModal = () => {
    Toast.success(t("messages.success"));
    refetch();
    onClose();
  }

  return (
    <div>
      <Dimmer inverted active={isLoading}>
        <Loader inverted>{t("loading")}</Loader>
      </Dimmer>

      <Modal
        open={open}
      >
        <Modal.Header>{product? t('edit') : t('add')}{t('product')}</Modal.Header>
        <Modal.Content>
          { errorMessages && 
            <Message negative>
              <Message.Header>{t('messages.error')}</Message.Header>
              <p>{errorMessages}</p>
            </Message>
          }
          <Form>
            <Form.Field required>
              <label>{t('products.name')}</label>
              <Form.Input
                fluid
                name='name'
                placeholder={t('products.name')}
                readOnly={!detail.is_active}
                value={formik.values.name}
                error={formik.errors.name}
                onChange={formik.handleChange}
              />
            </Form.Field>
            <Form.Group widths='equal'>
              <Form.Field 
                required
                fluid
                label={t('products.price_per_unit')}
                name={"price_per_unit"}
                placeholder={t('products.price_per_unit')}
                readOnly={!detail.is_active}
                value={formik.values.price_per_unit}
                error={formik.errors.price_per_unit}
                onChange={formik.handleChange}
                control={NumberFormat}
                thousandSeparator={true}
              />
              <Form.Field required>
                <label>{t('products.unit')}</label>
                <Form.Dropdown
                  fluid
                  search
                  selection
                  options={unitOptions(t)}
                  name='unit'
                  placeholder={t('products.unit')}
                  disabled={!detail.is_active}
                  value={formik.values.unit}
                  error={formik.errors.unit}
                  onChange={(event, data) => {
                    formik.setFieldValue('unit', data.value)
                    if (!formik.values.specific_gravity) {
                      formik.setFieldValue(
                        'specific_gravity',
                        product? product.specific_gravity : defaultModel.specific_gravity
                      )
                    }
                  }}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button 
            onClick={() => {
              formik.resetForm();
              onClose();
            }} 
            icon='cancel' 
            content={t('cancel')} 
          />
          { !readOnly && (
              product ? 
              product.is_active ?
              <Button loading={isLoading} type='button' onClick={formik.handleSubmit} icon='edit' content={t('edit')} color="blue" /> : 
              <Button loading={isLoading} type='button' onClick={handleReactivateProduct} icon='sync alternate' content={t('reactivate')} color="blue" /> :
              <Button loading={isLoading} type='button' onClick={formik.handleSubmit} icon='add' content={t('add')} color="green" />
            )
          }
        </Modal.Actions>
      </Modal>
    </div>
  );
}

const defaultModel = {
  name: '',
  price_per_unit: 0,
  unit: '',
  is_active: true,
};

ProductDetailModal.defaultProps = {
  open: false,
  readOnly: false,
  refetch: () => {},
  onClose: () => {},
}

ProductDetailModal.propTypes = {
  open: propTypes.bool,
  readOnly: propTypes.bool,
  refetch: propTypes.func,
  onClose: propTypes.func,
}