import React from 'react';
import propTypes from "prop-types";
import { useHistory } from 'react-router-dom';
import PageHeader from '../components/common/PageHeader';

export default function TicketDetailScreen() {
	const history = useHistory();

	return (
		<div style={{marginBottom: 24}}>
			<PageHeader 
				title={"ตาชั่ง"} 
				onBack={() => history.goBack()}/>
		</div>
	)
}

TicketDetailScreen.defaultProps = {}

TicketDetailScreen.propTypes = {}