import Cookies from 'js-cookie';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Container, Grid, Header, Icon, List, Segment } from 'semantic-ui-react';
import { URL_WEIGHT } from '../constances/urls';
import { PATH } from '../routes/Routes';
import { GET } from '../utils/HttpClient';
import { formatComma, dateToString } from '../utils/Util';

export default function LiffScalesScreen () {
	const params = useParams();
	const history = useHistory();
	const customer = Cookies.get('customer')
	const { t, i18n } = useTranslation();
	const [errorMessages, setErrorMessages] = React.useState(null);
	const [data, setData] = React.useState([]);

	const fetchData = async () => {
		if (errorMessages)
				setErrorMessages(null);

		try {
			const response = await GET(`${URL_WEIGHT.CUSTOMER_LIST_TICKET}${customer}/?page_size=30`);
			setData(response.data.results);
		} catch(error) {
			setErrorMessages(error.errorMessages);
		}
	}

	const gotoTicketDetail = (ticket) => {
		history.push(`${PATH.LIFF_TICKET}${ticket}/`)
	}

	React.useEffect(() => {
		if (customer) {
			fetchData()
		}
	}, [])

	return (
		<Container>
			<Header dividing textAlign='left' style={{marginTop: 24}}>
					{t('menu.scales')}
			</Header>

			<List style={{paddingBottom: 8}}>
				{ data.map((scale, id) => {
						return (
							<List.Item key={`scale-${id}`} style={{marginBottom: 8}} onClick={() => gotoTicketDetail(scale.id)}>
								<Segment>
									<div style={{textAlign: "left"}}>{`${dateToString(scale.created)}`}</div>
									<Grid>
										<Grid.Row>
											<Grid.Column textAlign={"left"} width={10}>
												<div style={{marginTop: 12, fontSize: 16}}>
													<Icon name={"truck"} />
													{` ${scale.license_plate}`}
												</div>
												<div style={{marginTop: 8, fontSize: 16}}>
													<Icon name={"tags"} />
													{` ${t(`vehicle_type.${scale.vehicle_type}`)}`}
												</div>
												<div style={{marginTop: 8, fontSize: 16}}>
													<Icon name={"certificate"} />
													{`${scale.product}`}
												</div>
											</Grid.Column>

											<Grid.Column textAlign={"right"} width={6}>
												<div style={{marginTop: 12}}>{t("scales.weigh")}</div>
												<div style={{marginTop: 4, fontSize: 24, fontWeight: "bold"}}>{`${formatComma(scale.net_weight)}`}</div>
												<div style={{marginTop: 8}}>{t("scales.price")}</div>
												<div style={{marginTop: 4, fontSize: 24, fontWeight: "bold"}}>{`${formatComma(scale.net_price)}`}</div>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Segment>
							</List.Item>
						)
					}) 
				}                
			</List>
		</Container>
	)
}